import styled from 'styled-components'
import { COLORS } from '@styles/colors'
import { FONTS, text2XL } from '@styles/fonts'
import { DEVICES } from '@constants/global'

export const Stats = styled.div``

export const StatsTitle = styled.h4`
  margin-bottom: 20px;

  @media ${DEVICES.mobile} {
    span {
      display: block;
    }
  }
`

export const StatItem = styled.div`
  margin-bottom: 40px;
  border-bottom: 2px solid ${COLORS.nature.base};
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;

  @media ${DEVICES.mobile} {
    margin-bottom: 32px;
  }
`

export const StatItemLabel = styled.div`
  margin-bottom: 16px;
  color: ${COLORS.navi};

  @media ${DEVICES.mobile} {
    color: ${COLORS.secondary};
  }
`

export const StatItemValue = styled.div`
  padding-bottom: 10px;
  ${text2XL};
  font-family: ${FONTS.medium};
  color: ${COLORS.secondary};
`
