import Tag from '@global/Tag'
import * as S from './Specialties.styled'

const Specialties = ({ specialties }) => {
  if (!specialties?.length) return

  return (
    <S.Specialties>
      {specialties.map((it) => (
        <Tag size='medium' key={it.specialty} tag={it.specialty} />
      ))}
    </S.Specialties>
  )
}

export default Specialties
