import { DEVICES } from '@constants/global'
import styled from 'styled-components'

export const Specialties = styled.div`
  margin-bottom: -16px;
  margin-top: 30px;

  @media ${DEVICES.mobile} {
    margin-top: 18px;
  }

  span {
    margin-right: 16px;
    margin-bottom: 16px;
  }
`
