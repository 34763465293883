import React from 'react'
import { logEvent } from '../../lib/analyticsService'
import { adfenix } from '../../lib/adfenixHelper'
class PhoneReveal extends React.Component {
  // TODO: Phone numbers have been shown due to request from BP - leaving this component in case that changes. Will need to manage state again.

  // Used to reveal phone numbers, will hide the last 4 characters
  // replaced with an elipsis, and show on click.

  state = {
    revealed: false
  }

  hidePhone = phoneNumber => {
    const { masker } = this.props
    return phoneNumber && phoneNumber.slice(0, 6) + (masker || '...')
  }

  showPhone = () => {
    const { type, name } = this.props

    // Show the number
    this.setState({ revealed: true })
    // GA: Log phone number view in Google Analytics
    logEvent(
      'View Phone Number',
      type || 'Unspecified type',
      name || 'Unspecified name'
    )
  }

  phoneClick = () => {
    const { type } = this.props
    switch (type) {
      case 'Agent': {
        // adfenix event log
        adfenix.contactrequestclick()
        break
      }
      case 'Office': {
        break
      }
    }
  }

  render() {
    const { phoneNumber, className, type, name } = this.props
    const { revealed } = this.state
    return (
      <a
        href={`tel:${phoneNumber}`}
        className={className}
        onClick={this.phoneClick}
      >
        {phoneNumber}
      </a>
    )
  }
}

PhoneReveal.defaultProps = {
  phoneNumber: '1234 5678',
  masker: '...'
}

export default PhoneReveal
