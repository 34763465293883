import React from 'react'
import PhoneReveal from '@global/PhoneReveal'
import * as S from './ContactDetails.styled'

const ContactAddress = ({ phone, fax, officeName, address, suburb, state, postcode }) => {
  return (
    <S.ContactDetailsAddress>
      {officeName && <S.LocationName>{officeName}</S.LocationName>}
      {phone && (
        <S.PhoneAndFax>
          <S.Phone>
            Phone: {' '}
            <PhoneReveal
              phoneNumber={phone}
              masker='----'
              type='Office'
            />
          </S.Phone>
          {fax && <div>|</div>}
          {fax && (
            <S.Fax>
              Fax: <a href={`tel:${fax}`}>{fax}</a>
            </S.Fax>
          )}
        </S.PhoneAndFax>
      )}
      {address}
      <br />
      {suburb} {state} {postcode}
    </S.ContactDetailsAddress>
  )
}

export default ContactAddress
