import styled, { css } from 'styled-components'
import { COLORS } from '@styles/colors'
import { DEVICES } from '@constants/global'

export const AreasCovered = styled.div`
  padding-bottom: 54px;

  h4 {
    margin-bottom: 20px;
  }

  button {
    margin-bottom: 10px;
  }

  @media ${DEVICES.mobile} {
    padding-bottom: 30px;

    button svg {
      display: none;
    }
  }
`

export const SpecialtySuburb = styled.div`
  padding: 6px 14px;
  background-color: ${COLORS.nature[95]};
  color: ${COLORS.secondary};
  border-radius: 20px;
  margin-right: 10px;
  margin-bottom: 10px;
  display: inline-flex;
  align-items: center;

  ${({ hidden }) =>
    hidden &&
    css`
      display: none;
    `}
`
