import { DEVICES } from '@constants/global'
import { COLORS } from '@styles/colors'
import { FONTS, text2XL, text3XL, text4XL } from '@styles/fonts'
import styled from 'styled-components'

export const OfficeWrap = styled.div`
  margin-bottom: 40px;
`

export const OfficeProfileActions = styled.div`
  padding-bottom: 28px;

  button {
    margin-bottom: 12px;
    min-height: 36px;
  }
`

export const OfficeHeading = styled.div`
  margin-bottom: 64px;

  @media ${DEVICES.mobile} {
    margin-bottom: 28px;
  }
`

export const OfficeName = styled.h1`
  ${text4XL};
  color: ${COLORS.secondary};

  @media ${DEVICES.mobile} {
    ${text2XL};
  }
`

export const OfficeAbout = styled.h2`
  ${text3XL};
  font-family: ${FONTS.medium};
  letter-spacing: 0.1px;
  text-transform: unset;
  margin-bottom: 32px;

  @media ${DEVICES.mobile} {
    ${text2XL};
    margin-bottom: 40px;
  }
`

export const OfficeSummary = styled.div`
  ${text2XL};
  font-family: ${FONTS.medium};
  color: ${COLORS.secondary};
  margin-bottom: 40px;
`

export const OfficeDetails = styled.div`
  padding-right: 86px;

  @media ${DEVICES.tabletMini} {
    padding-right: 0;
  }
`

export const Content = styled.div`
  color: ${COLORS.navi};
  position: relative;
  overflow: hidden;
  line-height: 22px;
  margin-bottom: 32px;

  p,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 16px;
  }
`
