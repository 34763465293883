const vCardCreator = {
  makeVCard({
    name = '',
    org = '',
    title = '',
    tel = '',
    address = '',
    email = '',
    photo = '',
  }) {
    let vcard = `BEGIN:VCARD
VERSION:3.0
N:${name}
FN:${name || ''}
${org ? `ORG:${org}` : ''}
${title ? `TITLE:${title}` : ''}
${photo ? `PHOTO;TYPE=JPEG;ENCODING=b:${photo}` : ''}
${tel ? `TEL;TYPE=WORK,VOICE:${tel}` : ''}
${address ? `ADR;TYPE=WORK,PREF:;;${address}` : ''}
${email ? `EMAIL:${email}` : ''}
REV:${this.formatDate(new Date())}
END:VCARD`

    this.downloadToFile(vcard, 'vcard.vcf', 'text/vcard')
  },

  formatDate(date) {
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      '0'
    )}-${String(date.getDate()).padStart(2, '0')}`
  },

  downloadToFile(content, filename, contentType) {
    const a = document.createElement('a')
    const file = new Blob([content], { type: contentType })

    a.href = URL.createObjectURL(file)
    a.download = filename
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)

    URL.revokeObjectURL(a.href)
  },
}

export default vCardCreator
