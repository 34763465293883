import React from 'react'
import ContactDetails from './ContactDetails'
import { Col, Container, Row, Section } from '@styles/global.styled'
import Social from '@global/Social'
import Button from '@global/Button'
import TradingHours from './TradingHours'
import AreasCovered from './AreasCovered'
import Stats from '@global/Stats'
import OtherLocations from './OtherLocations'
import { useEnquiryFormContext } from '@context/EnquiryFormContext'
import Specialties from './Specialties'
import * as S from './OfficeProfile.styled'

const OfficeProfile = (props) => {
  const {
    introText,
    street,
    suburb,
    state,
    postcode,
    telephone,
    fax,
    directionUrl,
    localOffices,
    officeHours,
    socialLinks,
    description,
    extraLocations,
    specialties,
    emailClickHandle,
    officeId,
    officeName,
    suburbsServiced,
    shareObject,
    email,
    stats,
    pageTitle,
  } = props
  const { setShowEnquiryForm } = useEnquiryFormContext()

  return (
    <Section>
      <Container
        data-gtm-office-name={officeName || 'Barry Plant'}
        data-gtm-office-id={officeId}
      >
        <S.OfficeWrap>
          <Row>
            <Col lg={8}>
              <S.OfficeDetails>
                <S.OfficeHeading>
                  <S.OfficeName>
                    {pageTitle || (
                      <>
                        Barry Plant
                        <br />
                        {officeName}
                      </>
                    )}
                  </S.OfficeName>
                  <Specialties specialties={specialties} />
                </S.OfficeHeading>
                <S.OfficeAbout>About Us</S.OfficeAbout>
                <S.OfficeSummary
                  dangerouslySetInnerHTML={{
                    __html: introText
                  }}
                />
                <S.Content dangerouslySetInnerHTML={{ __html: description }} />

                {stats.length > 0 ? (
                  <Stats
                    title={
                      <React.Fragment>
                        <span>{pageTitle}</span> By the numbers
                      </React.Fragment>
                    }
                    items={stats}
                  />
                ) : null}
              </S.OfficeDetails>
            </Col>
            <Col lg={4}>
            <S.OfficeProfileActions>
                <Row gutter={6}>
                  <Col xs={6} sm={6}>
                    <Button
                      border
                      block
                      color='secondary'
                      size='sm'
                      onClick={() => setShowEnquiryForm(true)}
                    >
                      Send Enquiry
                    </Button>
                  </Col>
                  <Col xs={6} sm={6}>
                    <a href={`tel:${telephone}`} target='_blank'>
                      <Button border block color='secondary' size='sm'>
                        Call Now
                      </Button>
                    </a>
                  </Col>
                </Row>
              </S.OfficeProfileActions>

              <ContactDetails
                locationName={pageTitle}
                address={street}
                suburb={suburb}
                state={state}
                postcode={postcode}
                phone={telephone}
                fax={fax}
                directionUrl={directionUrl}
                onClick={emailClickHandle}
                officeId={officeId}
                officeName={officeName}
                shareObject={shareObject}
                email={email}
                extraLocations={extraLocations}
              />

              {suburbsServiced && (
                <AreasCovered suburbsServiced={suburbsServiced} />
              )}

              <TradingHours officeHours={officeHours} />

              {localOffices.length > 0 && (
                <OtherLocations localOffices={localOffices} />
              )}
              <Social title='On Social Media' socialLinks={socialLinks} />
            </Col>
          </Row>
        </S.OfficeWrap>
      </Container>
    </Section>
  )
}

export default OfficeProfile
