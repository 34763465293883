import styled from 'styled-components'
import { COLORS } from '@styles/colors'
import { DEVICES } from '@constants/global'
import { FONTS } from '@styles/fonts'

export const TradingHours = styled.div`
  margin-bottom: 40px;

  h4 {
    margin-bottom: 12px;
  }
`

export const TradingHoursBox = styled.div`
  background-color: ${COLORS.nature[95]};
  padding: 20px;
  color: ${COLORS.secondary};

  b {
    font-family: ${FONTS.medium};
  }
`
