import * as S from './TradingHours.styled'

const TradingHours = ({ officeHours }) => {
  return (
    <S.TradingHours>
      <h4>Trading Hours</h4>
      <S.TradingHoursBox>
        <div dangerouslySetInnerHTML={{ __html: officeHours }} />
      </S.TradingHoursBox>
    </S.TradingHours>
  )
}

export default TradingHours
