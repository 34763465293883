import Button from '@global/Button'
import IconPlus from '@icons/Plus'
import { useState } from 'react'
import IconMinus from '@icons/Minus'
import { Link } from '@routes'
import * as S from './AreasCovered.styled'

const AreasCovered = ({ suburbsServiced }) => {
  const [showAll, setShowAll] = useState(false)
  const {
    value: { title, servicing_suburbs = [] },
  } = suburbsServiced

  if (!servicing_suburbs.length) return null

  const toggleShowAll = () => {
    setShowAll((prev) => !prev)
  }

  return (
    <S.AreasCovered>
      <h4>{title || 'Areas Covered'}</h4>
      {servicing_suburbs.map((it, index) => (
        <Link key={it.id} route={it.get_url} passHref>
          <S.SpecialtySuburb hidden={index > 6 && !showAll}>
            {it.title} {it.postcode}
          </S.SpecialtySuburb>
        </Link>
      ))}
      {servicing_suburbs.length > 6 && (
        <Button
          border
          endIcon={showAll ? <IconMinus /> : <IconPlus />}
          color='secondary'
          onClick={toggleShowAll}
        >
          {showAll ? 'View Less' : 'View More'}
        </Button>
      )}
    </S.AreasCovered>
  )
}

export default AreasCovered
