import styled from 'styled-components'
import { COLORS } from '@styles/colors'
import { FONTS, textLG } from '@styles/fonts'

export const ContactDetails = styled.div`
  padding: 20px;
  background-color: ${COLORS.nature[95]};
  margin-bottom: 40px;
  color: ${COLORS.secondary};

  h4 {
    ${textLG};
    letter-spacing: 1.2px;
    margin-bottom: 4px;
  }
`

export const ContactDetailsPhone = styled.div``

export const ContactDetailsAddress = styled.div`
  margin-bottom: 10px;
`

export const GoogleMapLink = styled.a`
  font-family: ${FONTS.medium};
  display: inline-block;
  margin-bottom: 20px;
  text-decoration: underline;
`

export const ContactDetailsActions = styled.div`
  display: flex;
  align-items: center;
`

export const SaveContact = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.25s ease-in;

  &:hover {
    color: ${COLORS.primary};
    text-decoration: underline;
  }

  svg {
    margin-right: 4px;
  }
`

export const WishlistWrap = styled.div`
  padding-top: 5px;
`

export const PhoneAndFax = styled.div`
  display: flex;
  gap: 5px;
`

export const Fax = styled.span`
`

export const Phone = styled.div`

`

export const LocationName = styled.div`
    font-family: ${FONTS.bold};
`

export const ExtraLocations = styled.div`
    margin-top: 20px;
`
