import ContactCard from '@global/ContactCard'
import * as S from './OtherLocations.styled'

const OtherLocations = ({ localOffices }) => {
  return (
    <S.OtherLocations>
      <h4>Our Other Locations</h4>
      {localOffices.map((it) => (
        <ContactCard
          key={`office-${it}`}
          name={
            <span>
              Barry Plant
              <br />
              {it.name}
            </span>
          }
          phone={it.phone}
          image={it.image}
          link={it.path}
        />
      ))}
    </S.OtherLocations>
  )
}

export default OtherLocations
