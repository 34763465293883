import React, { useContext } from 'react'
import Head from 'next/head'
import OfficeProfile from '@components/Offices/OfficeProfile'
import WagtailBlocksSection from '@global/Blocks/WagtailBlocksSection'
import { googleLocationLink, currencyFormat, numberFormat } from '@lib/helpers'
import { domain, hostname } from '@lib/variables'
import Hero from '@global/Blocks/Hero'
import getSocialLinksFromPageData from '@lib/helpers/social'
import dynamic from 'next/dynamic'
import PropertySearchFrom from '@global/PropertySearchFrom'
import { OfficeSiteContext } from '@context/OfficeSiteContext'

const StickyNavOffice = dynamic(
  () => import('@components/Offices/OfficeProfile/StickyNavOffice'),
  { ssr: false }
)

const OfficePage = ({ pageData }) => {
  const { currentSite } = useContext(OfficeSiteContext)
  const relatedOffices = pageData.related_offices?.map((it) => ({
    image: it.office.image,
    path: it.office.path,
    name: it.office.title,
    link: it.office.path,
  }))

  const socialLinks = getSocialLinksFromPageData(pageData)

  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'RealEstateAgent',
    name:
      pageData.extra_locations &&
      pageData.extra_locations.length > 0 &&
      pageData.office_name,
    image: pageData.office_image && pageData.office_image.url,
    '@id': '',
    url: `${domain}${pageData.meta.path || ''}`,
    telephone: pageData.phone,
    address: {
      '@type': 'PostalAddress',
      streetAddress: pageData.address,
      addressLocality: pageData.suburb,
      addressRegion: pageData.state,
      postalCode: pageData.postcode,
      addressCountry: 'AU',
    },
  }

  const officeName = pageData.office_name
  const pageTitle = pageData.custom_link_title || `Barry Plant ${officeName}`

  const homeSearchType = pageData?.meta?.home_search_type

  const shareObject = {
    url: `https://${hostname()}${pageData.meta?.path}`,
    quote: officeName,
    hastag: '#barryplant',
    hastags: ['#barryplant', '#' + officeName],
    media: pageData.office_image?.url || '',
    image: pageData.office_image?.url || '',
    subject: 'Check out this real estate agent from Barry Plant',
  }

  const suburbsServiced = pageData.content_blocks.find(
    (it) => it.type === 'suburbs_serviced'
  )

  const remainingBlocks = pageData.content_blocks.filter(
    (it) => it.type !== 'suburbs_serviced' && it.type !== 'statistics_panel'
  )

  const getOfficeStats = () => {
    const officeStat = pageData.content_blocks.find(
      (it) => it.type === 'statistics_panel'
    )?.value

    if (!officeStat) return []

    const createStat = (condition, label, valueFunc, valueKey) => {
      return condition
        ? { label, value: valueFunc(officeStat[valueKey], true) }
        : null
    }

    const stats = [
      createStat(
        officeStat.show_current_sales_count,
        'Properties for sale',
        numberFormat,
        'current_sale_count'
      ),
      createStat(
        officeStat.show_current_sold_count,
        'Properties sold over last 12 months',
        numberFormat,
        'current_sold_count'
      ),
      createStat(
        officeStat.show_median_sales_price,
        'Median Sales Price (houses)',
        currencyFormat,
        'median_sales_price'
      ),
      createStat(
        officeStat.show_days_on_sale_market,
        'Median days on market (sales)',
        numberFormat,
        'days_on_sale_market'
      ),
      createStat(
        officeStat.show_current_rental_count,
        'Properties for lease',
        numberFormat,
        'current_rental_count'
      ),
      createStat(
        officeStat.current_rentals_leased_count,
        'Properties leased over 12 months',
        numberFormat,
        'current_rentals_leased_count'
      ),
      createStat(
        officeStat.show_median_rental_price,
        'Median rental price (houses)',
        currencyFormat,
        'median_rental_price'
      ),
      createStat(
        officeStat.show_days_on_rental_market,
        'Median days on market (rentals)',
        numberFormat,
        'days_on_rental_market'
      ),
    ].filter(Boolean)

    if (officeStat.custom_statistics?.length > 0) {
      const customStats = officeStat.custom_statistics.map((it) => ({
        label: it.title,
        value: it.value,
      }))
      return stats.concat(customStats).slice(0, 8)
    }

    return stats.slice(0, 8)
  }

  return (
    <>
      <Head>
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(structuredData),
          }}
        />
      </Head>

      <StickyNavOffice
        name={pageTitle}
        image={pageData.office_image?.url}
        phone={pageData.phone}
        directionUrl={googleLocationLink(pageData)}
        address={`${pageData.address} ${pageData.suburb} ${pageData.state} ${pageData.postcode}`}
        shareObject={shareObject}
        officeId={pageData.id}
        officeSlug={pageData.meta.slug}
      />

      {(pageData.featured_image?.url || pageData.hero_video_url) && (
        <Hero
          bgImageUrl={pageData.featured_image?.url}
          bgVideoUrl={pageData.hero_video_url}
          searchForm={
            homeSearchType !== 'none' &&
            <PropertySearchFrom searchPages={currentSite.searchSettings} homeSearchType={homeSearchType}/>
          }
          title={pageData?.hero_text}
          subTitle={pageData?.hero_secondary_button_text}
          office
        />
      )}

      <OfficeProfile
        introText={pageData.subtitle || ''}
        street={pageData.address}
        suburb={pageData.suburb}
        state={pageData.state}
        postcode={pageData.postcode}
        telephone={pageData.phone}
        fax={pageData.fax}
        localOffices={relatedOffices}
        specialties={pageData.specialties}
        description={pageData.description}
        directionUrl={googleLocationLink(pageData)}
        socialLinks={socialLinks}
        officeHours={pageData.trading_hours}
        extraLocations={pageData.extra_locations}
        officeId={pageData.id}
        officeName={pageData.office_name}
        suburbsServiced={suburbsServiced}
        shareObject={shareObject}
        pageTitle={pageTitle}
        email={pageData.email}
        stats={getOfficeStats()}
      />

      {remainingBlocks.length > 0 && (
        <WagtailBlocksSection blocks={remainingBlocks} />
      )}
    </>
  )
}

export default OfficePage
