import { Col, Row } from '@styles/global.styled'
import * as S from './Stats.styled'

const Stats = ({ title, items, titleStyle }) => {
  if (!items.length || !items.filter((it) => it.value).length) return null

  return (
    <S.Stats>
      <S.StatsTitle style={titleStyle}>{title}</S.StatsTitle>
      <Row gutter={21}>
        {items.map(
          (it, index) =>
            it.value && (
              <Col xs={6} sm={6} md={3} key={`stat-${index}`} flex>
                <S.StatItem>
                  <S.StatItemLabel>{it.label}</S.StatItemLabel>
                  <S.StatItemValue>{it.value}</S.StatItemValue>
                </S.StatItem>
              </Col>
            )
        )}
      </Row>
    </S.Stats>
  )
}

export default Stats
